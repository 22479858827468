import renameFunc from "utils/helpers/renameFunc";
import { camelCase as _camelCase, get as _get } from "lodash";

export const actionTypes = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION 🚀 💬",
  CHECK_AUTHORIZATION_SUCCESS: "CHECK_AUTHORIZATION_SUCCESS 🚀 ✅",
  CHECK_AUTHORIZATION_FAILED: "CHECK_AUTHORIZATION_FAILED 🚀 ❌",

  REGISTER_REQUEST: "REGISTER_REQUEST 🚀 💬",
  REGISTER_REQUEST_SUCCESS: "REGISTER_REQUEST_SUCCESS 🚀 ✅",
  REGISTER_REQUEST_FAILED: "REGISTER_REQUEST_FAILED 🚀 ❌",

  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST 🚀 💬",
  VERIFY_EMAIL_REQUEST_SUCCESS: "VERIFY_EMAIL_REQUEST_SUCCESS 🚀 ✅",
  VERIFY_EMAIL_REQUEST_FAILED: "VERIFY_EMAIL_REQUEST_FAILED 🚀 ❌",

  LOGIN_REQUEST: "LOGIN_REQUEST 🚀 💬",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS 🚀 ✅",
  LOGIN_REQUEST_FAILED: "LOGIN_REQUEST_FAILED 🚀 ❌",

  FORGET_PASSWORD_REQUEST: "FORGET_PASSWORD_REQUEST 🚀 💬",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS 🚀 ✅",
  FORGET_PASSWORD_FAILED: "FORGET_PASSWORD_FAILED 🚀 ❌",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST 🚀 💬",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS 🚀 ✅",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED 🚀 ❌",

  LOGOUT: "LOGOUT 🚀 💬",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS 🚀 ✅",
  LOGOUT_FAILED: "LOGOUT_FAILED 🚀 ❌",

  FLUSH_AUTH_REDUCER: "FLUSH_AUTH_REDUCER 🚽",

  LOGIN_OPEN: "LOGIN_OPEN 🚽",
};
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id);

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    };
  };

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName);
  return accum;
}, {});
