import React, { Suspense } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { get as _get } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";
import ScrollToTop from "utils/helpers/ScrollToTop";
import Loader from "components/Utils/loader";
import { publicRoutes, privateRoutes , NotFound } from "./route.constants";
import ErrorBoundary from "./ErrorBoundary";

function PublicRoute({ children, isRestricted, ...rest }) {
  const User = useSelector((state) => state.User || {}, shallowEqual);
  const isLoggedIn = _get(User, "token", false);

  //   console.log(window.location.pathname)
  // if (isLoggedIn && window.location.pathname === "/") {
  //   <Route
  //     {...rest}
  //     render={({ location }) => (
  //       <Redirect
  //         to={{
  //           pathname: "/home",
  //           state: { from: location },
  //         }}
  //       />
  //     )}
  //   />;
  // }
  if (!isLoggedIn && !isRestricted) {
    return <Route {...rest} render={({ location }) => children} />;
  }

  if (isLoggedIn && !isRestricted) {
    return <Route {...rest} render={({ location }) => children} />;
  }

  if (!isLoggedIn && isRestricted) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
  return null;
}

function PrivateRoute({ children, isRestricted, ...rest }) {
  const User = useSelector((state) => state.User || {}, shallowEqual);
  const isLoggedIn = _get(User, "token", false);

  if (isLoggedIn && isRestricted) {
    return <Route {...rest}>{children}</Route>;
  }
  if (!isLoggedIn && isRestricted) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
  return null;
}
function Routes(props) {
  reactIntl.injectIntl(props.intl);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <ScrollToTop>
            <Switch>
              {publicRoutes.map((route) => (
                <PublicRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  isRestricted={false}
                >
                  <route.component />
                </PublicRoute>
              ))}
              {privateRoutes.map((route) => (
                <PrivateRoute
                  exact={route.exact}
                  key={route.path}
                  path={route.path}
                  isRestricted={true}
                >
                  <route.component />
                </PrivateRoute>
              ))}
              <Route component={NotFound} />
            </Switch>
          </ScrollToTop>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
export default injectIntl(Routes);
