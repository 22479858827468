import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AppLocale from "config/translation";
import client from "utils/plugins/ApiClient";
import { actions as userprofileActions } from 'redux/userprofile/actions'
import { get as _get } from "lodash";

function AppProvider({ children }) {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const currentAppLocale = AppLocale[locale];

  const { profile, isLoading: isProfileLoading } = useSelector(
    (state) => state.Userprofile.userprofile
  )

  const User = useSelector((state) => state.User || {}, shallowEqual);
  const isLoggedIn = _get(User, "token", false);

  const dispatch = useDispatch()

  useEffect(() => {
    if (locale === "ar") {
      document.querySelector("html").setAttribute("dir", "rtl");
      document.querySelector("html").setAttribute("lang", "ar");
      client.defaults.headers.Language = "ar";
    } else {
      document.querySelector("html").setAttribute("dir", "ltr");
      document.querySelector("html").setAttribute("lang", "en");
      client.defaults.headers.Language = "en";
    }
  }, [locale]);

  useEffect(()=>{
    if(localStorage.getItem("@aa-current_currency")){
      client.defaults.headers["currency"] = localStorage.getItem("@aa-current_currency");
    }else{
      localStorage.setItem("@aa-current_currency" , "USD")
      client.defaults.headers["currency"] = "USD";
    } 
  },[])

  useEffect(() => {
    if (!isProfileLoading && !profile && isLoggedIn) {
      dispatch(userprofileActions.userprofileRequest())
    }
  }, [isLoggedIn])

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <>
        {locale === "ar"}
        {children}
      </>
    </IntlProvider>
  );
}

export default AppProvider;
