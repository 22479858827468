import { lazy } from "react";

export const PUBLIC_ROUTE = {
  LANDING: "/",
  MODALS: "/modals",
  SKELETON: "/skeleton",
  BUTTONS: "/buttons",
  VERIFY: "/verify",
  ACCORDION: "/accordion",
  CARDS: "/cards",
  VIEWCOURSE: "/courses/:id",
  SEARCHRESULTS: "/search-results",
  INSTRUCTORS: "/instructors/:id",
  FAQ: "/faq",
  CONDITIONPOLICIES: "/condition-policies/:category",
};

export const PRIVATE_ROUTE = {
  HOME: "/home",
  CART: "/cart",
  CHECKOUT: "/checkout",
  CLASSROOM: "/classroom/:id",
  BUYSUCCESS: "/successfully-purshased/:id",
  MYCOURSES: "/my-courses",
  ACCOUNT: "/account",
  INSTRUCTOR: "/instructor/:instructorId",
};

export const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("views/Landing")),
  },
  {
    path: PUBLIC_ROUTE.MODALS,
    exact: true,
    component: lazy(() => import("views/components/Modals")),
  },
  {
    path: PUBLIC_ROUTE.BUTTONS,
    exact: true,
    component: lazy(() => import("views/components/Buttons")),
  },
  {
    path: PUBLIC_ROUTE.SKELETON,
    exact: true,
    component: lazy(() => import("views/components/Skeleton")),
  },
  {
    path: PUBLIC_ROUTE.ACCORDION,
    exact: true,
    component: lazy(() => import("views/components/Accordion")),
  },
  {
    path: PUBLIC_ROUTE.CARDS,
    exact: true,
    component: lazy(() => import("views/components/Cards")),
  },
  {
    path: PUBLIC_ROUTE.VIEWCOURSE,
    exact: true,
    component: lazy(() => import("views/ViewCourse")),
  },
  {
    path: PUBLIC_ROUTE.SEARCHRESULTS,
    exact: true,
    component: lazy(() => import("views/SearchResults")),
  },
  {
    path: PUBLIC_ROUTE.INSTRUCTORS,
    exact: true,
    component: lazy(() => import("views/Instructor")),
  },
  {
    path: PUBLIC_ROUTE.FAQ,
    exact: true,
    component: lazy(() => import("views/Faq")),
  },
  {
    path: PUBLIC_ROUTE.CONDITIONPOLICIES,
    exact: true,
    component: lazy(() => import("views/ConditionPolicies")),
  },
];
export const privateRoutes = [
  {
    path: PRIVATE_ROUTE.HOME,
    component: lazy(() => import("views/Home")),
    exact: true,
  },
  {
    path: PRIVATE_ROUTE.CART,
    exact: true,
    component: lazy(() => import("views/Cart")),
  },
  {
    path: PRIVATE_ROUTE.CHECKOUT,
    exact: true,
    component: lazy(() => import("layouts/Checkout")),
  },
  {
    path: PRIVATE_ROUTE.CLASSROOM,
    exact: true,
    component: lazy(() => import("views/Classroom")),
  },
  {
    path: PRIVATE_ROUTE.BUYSUCCESS,
    exact: true,
    component: lazy(() => import("views/SuccessfullyPurshased")),
  },
  {
    path: PRIVATE_ROUTE.MYCOURSES,
    component: lazy(() => import("views/MyCourses")),
    exact: false,
  },
  {
    path: PRIVATE_ROUTE.ACCOUNT,
    exact: false,
    component: lazy(() => import("views/Account")),
  },
  {
    path: PRIVATE_ROUTE.INSTRUCTOR,
    exact: false,
    component: lazy(() => import("layouts/Instructor")),
  },
];
export const NotFound = lazy(() => import("views/404"));
