import { combineReducers } from "redux";
import App from "redux/app/reducer";
import Auth from "redux/auth/reducer";
import User from "redux/User/reducer";
import Order from "redux/order/reducer";
import LanguageSwitcher from "redux/languageSwitcher/reducer";
import Userprofile from 'redux/userprofile/reducer'

const allReducers = combineReducers({
  App,
  Auth,
  User,
  Order,
  LanguageSwitcher,
  Userprofile
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};
export default rootReducer;
