/* eslint-disable no-confusing-arrow, max-len */
const { isString: _isString } = require('lodash')

const execIfFunc = (x, ...args) => typeof x === 'function'
  ? x(...args)
  : x

/**
 *
 * -deprecated In favor of `inCase` proto functions `inCase.default()...`
 *
 * @param {*} fallback - default return if none matched
 * @param {object} cases - cases to switch on
 * @param {*} expiration - to match against "must be string-able"
 *
 * @return matched case value or `fallback`
 */
const _inCase = (cases) => (fallback) => (expression) => (expression in cases)
  ? cases[expression]
  : fallback

const inCaseRegex = (cases) => (fallback) => (expression) => {
  let hasMatch = false
  let match = null

  Object.entries(cases)
    .forEach(([k, v]) => {
      const kre = k.split(/\//)

      if (kre.length === 3 && kre[0] === 're') {
        const re = RegExp(kre[1], kre[2])
        const m = `${expression}`.match(re)

        if (m && !hasMatch) {
          hasMatch = true
          match = v
        }
      }
    })

  if (hasMatch) {
    return match
  }

  return _inCase(cases)(fallback)(expression)
}

/**
 *
 * -deprecated In favor of `inCase` proto functions `inCase.default()...`
 *
 * @param {object} cases - cases to switch on
 * @param {*} fallback - default return if none matched
 * @param {*} expiration - to match against "must be string-able"
 * @return {function}
 */
const inCaseExec = (cases) => (fallback) => (expression) => execIfFunc(
  inCaseRegex(cases)(fallback)(expression))

/**
 *
 * -deprecated In favor of `inCase` proto functions `inCase.default()...`
 *
 * @type {function}
 * @param {object} cases - cases to switch on
 * @param {*} fallback - default return if none matched
 * @param {*} expiration - to match against "must be string-able"
 * @param {Array} args - args to pass to match function on execution
 *
 * @return {function}
 */
const inCaseExecCurry = (cases) => (fallback) => (expression) => (...args) => execIfFunc(
  inCaseRegex(cases)(
    execIfFunc(fallback, ...args)
  )(
    execIfFunc(expression, ...args)
  ), ...args
)

/* eslint-disable no-shadow */
const inCaseChained = {
  toString() {
    return '<inCase>'
  },

  curry(funcName) {
    const repr = `<inCase:curry(${funcName})>`

    function check(expression) {
      const repr = `<inCase:curry(${funcName}):condition(${expression})>`

      return {
        stack: new Error(repr),

        toString() {
          return repr
        },

        otherwise(fallback) {
          const repr = `<inCase:curry(${funcName}):condition(${expression}):otherwise(${toRepr(fallback)})>`

          return {
            stack: new Error(repr),

            toString() {
              return repr
            },

            cases(cases) {
              return renameFunc(inCaseExecCurry(cases)(fallback)(expression), funcName)
            },
          }
        },
      }
    }

    return {
      check,
      condition: check,
      stack: new Error(repr),
      toString() {
        return repr
      },
    }
  },

  check(expression) {
    const repr = `<inCase:check(${expression})>`

    return {
      stack: new Error(repr),

      toString() {
        return repr
      },

      otherwise(fallback) {
        const repr = `<inCase:check(${expression}):otherwise(${toRepr(fallback)})>`

        return {
          stack: new Error(repr),

          toString() {
            return repr
          },

          cases(cases) {
            const repr = `<inCase:check(${expression}):otherwise(${toRepr(fallback)}):cases(${Object.keys(cases)})>`

            return {
              stack: new Error(repr),
              value: () => inCaseRegex(cases)(fallback)(expression),
              exec: () => inCaseExec(cases)(fallback)(expression),
              curry: (funcName) => renameFunc(inCaseExecCurry(cases)(fallback)(expression), funcName),
              toString: () => inCaseRegex(cases)(fallback)(expression),
            }
          },
        }
      },

      default(fallback) {
        const repr = `<inCase:curry(${expression}):default(${toRepr(fallback)})>`

        return {
          stack: new Error(repr),

          toString() {
            return repr
          },

          cases: (cases) => {
            const repr = `<inCase:curry(${expression}):default(${toRepr(fallback)})>`

            return {
              stack: new Error(repr),
              value: () => inCaseRegex(cases)(fallback)(expression),
              exec: () => inCaseExec(cases)(fallback)(expression),
              curry: (funcName) => renameFunc(inCaseExecCurry(cases)(fallback)(expression), funcName),
              toString: () => inCaseRegex(cases)(fallback)(expression),
            }
          },
        }
      },
    }
  },

  condition: {}, // same as check

  _exec() {
    return {
      condition: (expression) => ({
        otherwise: (fallback) => ({
          cases: (cases) => inCaseExec(cases)(fallback)(expression),
        }),
      }),

      check: (expression) => ({
        otherwise: (fallback) => ({
          cases: (cases) => inCaseExec(cases)(fallback)(expression),
        }),
      }),
    }
  },

  value() {
    return {
      condition: (expression) => ({
        otherwise: (fallback) => ({
          cases: (cases) => inCaseRegex(cases)(fallback)(expression),
        }),
      }),

      check: (expression) => ({
        otherwise: (fallback) => ({
          cases: (cases) => inCaseRegex(cases)(fallback)(expression),
        }),
      }),
    }
  },
}

inCaseChained.condition = inCaseChained.check
/* eslint-enable  no-shadow */

function inCase(incase) {
  const isCurry = _isString(incase)

  if (isCurry) {
    return inCase.curry(incase)
  }

  console.warn('[[Deprecation warning]] `inCase({...})(...)(...)` this usage is deprecated, please refer to docs for new usage.')
  return _inCase(incase)
}

inCase.exec = inCaseExec
inCase.execute = inCaseExec
inCase.execIfFunc = inCaseExec

/**
 * @typedef {function} CurryFunction
 * @param {string} functionName - used to rename returned curry function
 * @return InCase_Condition
 */

/**
 * @typedef {Object<string|*>} InCase_SwitchCases
 * @property {function(): *} value - resolve matched value
 * @property {function(...args: *): *} exec - resolve matched value, exec if func. resolve using `args`
 * @property {function(functionName): function} exec - return resolve function, exec if func. resolve using `args`
 */

/**
 * @typedef {Object} InCase_Cases
 * @property {function(Object): *} cases
 */

/**
 * @typedef {Object} InCase_Otherwise
 * @property {function(*|function): InCase_Cases} otherwise
 */

/**
 * @typedef {Object} InCase_Condition
 * @property {function(*|function): InCase_Otherwise} condition
 */


/**
 * @example <caption> Get Match Case Value </caption>
 * const bar = true
 *
 * const foo = inCase
 *  .condition(bar)
 *  .otherwise(null)
 *  .cases({
 *    1: 'fooBar',
 *    two: 'fooFoobar',
 *    true: 'bar',
 *  })
 *  .value() // >> bar
 *
 * @example <caption> Using Regex match </caption>
 * const bar = true
 *
 * const foo = inCase
 *  .condition(bar)
 *  .otherwise(null)
 *  .cases({
 *    1: 'fooBar',
 *    're/foo\d+/g': 'fooFoobar',
 *    're/[FB][oa]+r?/g': 'fooFoobar',
 *    true: 'bar',
 *  })
 *  .value() // >> bar
 *
 * @example <caption> Get A Curry function to return Match Case Value resolved</caption>
 * const bar = true
 *
 * const foo = inCase
 *  .curry('resolveFooBar')
 *  .condition((b) => b)
 *  .otherwise(null)
 *  .cases({
 *    1: 'fooBar',
 *    two: 'fooFoobar',
 *    true: 'bar',
 *  }) // >> [Function resolveFoobar]
 *
 *  foo(bar) // >> 'bar'
 *
 *
 * @type {Object}
 * @property {CurryFunction} curry
 * @property {function(*|function): InCase_Otherwise} check
 * @property {function(*|function): InCase_Otherwise} condition
 */
const InCase = Object.assign(inCase, inCaseChained)

export default InCase


function renameFunc(func, name) {
  /* eslint-disable no-param-reassign */
  Object.defineProperty(func, 'name', { writable: true })
  func.name = name
  Object.defineProperty(func, 'name', { writable: false })
  /* eslint-enable no-param-reassign */

  return func
}


function toRepr(fallback) {
  try {
    if (typeof fallback === 'function') {
      return repr()
    }

    return JSON.stringify(fallback)
  } catch (e) {
    toString.e = e
  }

  return repr()

  /* ************************************ */
  function repr() {
    const type = typeof fallback
    const name = fallback?.name || ''

    return `[${type} ${name}]`
  }
}
