import HandleAxiosError from "utils/helpers/HandleAxiosError";
import ApiClient from "utils/plugins/ApiClient";
import { toast } from "react-toastify";
export const createOrder = (payload) =>
  ApiClient.post("/orders", payload)
    .then((response) => {
      toast.success(response.data.message);
      return response.data.response.data;
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      // throw HandleAxiosError(error);
    });

export const getOrders = (payload) => {
  return ApiClient.get("/orders?order_type=pending", payload)
    .then((response) => {
      return response.data.response.data[0];
    })
    .catch((error) => {
      throw HandleAxiosError(error);
    });
};

export const deleteOrder = ({ id, payload }) => {
  return ApiClient.delete(`/orders/item/${id}`, payload)
    .then((response) => {
      toast.success(response.data.message);
      // console.log(response.data.response.data.id);
      return response.data.response.data;
    })
    .catch((error) => {
      toast.error(error);
      throw HandleAxiosError(error);
    });
};
