import { actionTypes } from "./actions";

const initState = {
  createOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  deleteOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  orders: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    pendingOrders: null,
  },
};

const ordersRequest = (state, payload) => ({
  ...state,
  orders: { isLoading: true },
});
const ordersRequestSuccess = (state, payload) => ({
  ...state,
  orders: {
    isLoading: false,
    isSuccess: true,
    // successMessage: payload?.message,
    successMessage: null,
    isFailed: false,
    error: null,
    pendingOrders: payload,
  },
});
const ordersRequestFailed = (state, payload) => ({
  ...state,
  orders: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// order
const createOrderRequest = (state, payload) => ({
  ...state,
  createOrder: { isLoading: true },
});
const createOrderSuccess = (state, payload) => {
  // console.log(state.orders.pendingOrders.items.concat(payload));
  return {
    ...state,

    createOrder: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
    orders: {
      ...state,
      pendingOrders: {
        ...state.orders.pendingOrders,
        count_items:
          state.orders.pendingOrders?.count_items > 0
            ? state.orders.pendingOrders?.count_items + 1
            : 1,
        price: payload?.price,
        total_with_tax: payload?.total_with_tax,
        tax: payload?.tax,
        items: state.orders.pendingOrders?.items?.concat(payload.order_item),
      },
    },
  };
};
const createOrderFailed = (state, payload) => ({
  ...state,
  createOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

// delete order
const deleteOrderRequest = (state, payload) => ({
  ...state,
  deleteOrder: { isLoading: true },
});
const deleteOrderSuccess = (state, payload) => {
  // const deletedIndex = state.orders.pendingOrders.items.findIndex((item) => {
  //   return item.id === payload.id;
  // });
  // console.log(deletedIndex);
  let filteredItems =null;
  if(payload?.id){
    filteredItems = state.orders.pendingOrders.items.filter(
      (item) => item.id != payload.id
    );
  }
  return filteredItems ? {
    ...state,
    deleteOrder: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
    orders: {
      ...state,
      pendingOrders: {
        ...state.orders.pendingOrders,
        count_items: state.orders.pendingOrders.count_items - 1,
        price: payload.price,
        total_with_tax: payload.total_with_tax,
        tax: payload.tax,
        items: filteredItems,

        // items: state.orders.pendingOrders.items.splice(deletedIndex, 1),
      },
    },
  }: 
  initState
  ;
};
const deleteOrderFailed = (state, payload) => ({
  ...state,
  deleteOrder: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
const ordersAddToFav = (state, payload) => {
  const updatedItems = state.orders.pendingOrders.items.map(el => 
    el.course.id === payload.id ? 
      {
        ...el, 
        course:{
          ...el.course , 
          user_favorite: true
        }
      } 
    : 
    el 
  );
  return {
    ...state,
    orders: {
      ...state.orders,
      pendingOrders: {
        ...state.orders.pendingOrders,
        items: updatedItems,
      },
    },
  };
};
const ordersDelToFav = (state, payload) => {
  const updatedItems = state.orders.pendingOrders.items.map(el => 
    el.course.id === payload.id ? 
      {
        ...el, 
        course:{
          ...el.course , 
          user_favorite: false
        }
      } 
    : 
    el 
  );
  return {
    ...state,
    orders: {
      ...state.orders,
      pendingOrders: {
        ...state.orders.pendingOrders,
        items: updatedItems,
      },
    },
  };
};
export default function orderReducer(state = initState, { type, payload }) {
  switch (type) {
    // create order
    case actionTypes.CREATE_ORDERS_REQUEST:
      return createOrderRequest(state, payload);
    case actionTypes.CREATE_ORDER_SUCCESS:
      return createOrderSuccess(state, payload);
    case actionTypes.CREATE_ORDER_FAILED:
      return createOrderFailed(state, payload);
    //delete order
    case actionTypes.DELETE_ORDERS_REQUEST:
      return deleteOrderRequest(state, payload);
    case actionTypes.DELETE_ORDER_SUCCESS:
      return deleteOrderSuccess(state, payload);
    case actionTypes.DELETE_ORDER_FAILED:
      return deleteOrderFailed(state, payload);
    case actionTypes.ORDERS_REQUEST:
      return ordersRequest(state, payload);
    case actionTypes.ORDERS_REQUEST_SUCCESS:
      return ordersRequestSuccess(state, payload);
    case actionTypes.ORDERS_REQUEST_FAILED:
      return ordersRequestFailed(state, payload);
    case actionTypes.ORDERS_ADD_TO_FAV:
      return ordersAddToFav(state, payload);
    case actionTypes.ORDERS_DEL_TO_FAV:
      return ordersDelToFav(state, payload);
    default:
      return state;
  }
}
