import HandleAxiosError from 'utils/helpers/HandleAxiosError'
import ApiClient from 'utils/plugins/ApiClient'

export const registerUser = (payload) =>{
  ApiClient.defaults.headers["action-name"] = "register";
  return ApiClient.post('/user/register', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })
}
export const verifyEmail = (payload) => {
  return ApiClient.post('/user/email/verify', payload)
    .then((respose) => {
      return respose.data.response.data
    })
    .catch((error) => {
      throw HandleAxiosError(error)
    })
}
  

export const loginUser = (payload) =>
  ApiClient.post('/user/login', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })

export const forgetPassword = (payload) =>{
  ApiClient.defaults.headers["action-name"] = "forget";
  return ApiClient.post('/user/forget-password', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })
}
export const resetPassword = (payload) =>
  ApiClient.post('/user/password/reset', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      throw HandleAxiosError(error)
    })
