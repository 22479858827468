import Enlang from './entries/en-US';
import Arlang from './entries/ar_SA';

const AppLocale = {
  en: Enlang,
  default: Enlang,
  ar: Arlang,
};

export default AppLocale;
