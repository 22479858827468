import axios from "axios";
import history from "utils/helpers/history";
import { store, dispatch } from "../../redux";
import { actionTypes } from "redux/auth/actions";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_BE_API,
  validateStatus: (status) => {
    if ((status === 401 && !localStorage.getItem("@aa__loginStatus")) || status === 403) {
      // logout
      // history.push('/signin')
      dispatch({
        type: actionTypes.LOGOUT,
      });
      return false;
    }
    if (status >= 200 && status <= 300) return true;
    return false;
  },
});

const token = localStorage.getItem("@aa__token") || 'null';

client.defaults.headers.common.Authorization = `Bearer ${token}`;
client.defaults.headers.Language = "en";
client.defaults.headers["User-Agent-Type"] = "web";
client.defaults.headers["currency"] = "USD";

// client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export default client;
