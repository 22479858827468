import { actionTypes } from './actions'

const initState = {
  userprofile: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    profile: null,
  },
}

const userprofileRequest = (state, payload) => ({
  ...state,
  userprofile: { isLoading: true },
})
const userprofileRequestSuccess = (state, payload) => ({
  ...state,
  userprofile: {
    isLoading: false,
    isSuccess: true,
    // successMessage: payload?.message,
    successMessage: null,
    isFailed: false,
    error: null,
    profile: payload,
  },
})
const userprofileRequestFailed = (state, payload) => ({
  ...state,
  userprofile: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
})
const updateProfileReducerData = (state, payload) => ({
  ...state,
  userprofile: {
    ...state.userprofile,
    profile:{
      ...state.userprofile.profile,
      ...payload,
    }
  },
})

export default function userprofileReducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actionTypes.USERPROFILE_REQUEST:
      return userprofileRequest(state, payload)
    case actionTypes.USERPROFILE_REQUEST_SUCCESS:
      return userprofileRequestSuccess(state, payload)
    case actionTypes.USERPROFILE_REQUEST_FAILED:
      return userprofileRequestFailed(state, payload)
    case actionTypes.UPDATE_PROFILE_REDUCER_DATA:
      return updateProfileReducerData(state, payload)
    default:
      return state
  }
}
