import { actionTypes } from "./actions";

const initState = {
  isLoading: false,
  isSuccess: false,
  successMessage: null,
  isFailed: false,
  error: null,
};

const loginRequestSucces = (state, payload) => ({
  ...state,
  ...payload,
});

const profileRequest = (state, payload) => ({
  ...state,
  isLoading: true,
});
const profileRequestSucces = (state, payload) => ({
  ...state,
  user: {
    ...payload.user,
  },
  isLoading: false,
  isSuccess: true,
  successMessage: payload.message,
  isFailed: false,
  error: null,
});
const profileRequestFailed = (state, payload) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  successMessage: null,
  isFailed: true,
  error: payload,
});

const updateUserReducerData = (state, payload) => ({
  ...state,
  user: {
    ...state.user,
    ...payload,
  },
});

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actionTypes.LOGIN_REQUEST_SUCCESS:
      // console.log(payload)
      return loginRequestSucces(state, payload);
    case actionTypes.PROFILE_REQUEST:
      return profileRequest(state, payload);
    case actionTypes.PROFILE_REQUEST_SUCCESS:
      return profileRequestSucces(state, payload);
    case actionTypes.PROFILE_REQUEST_FAILED:
      return profileRequestFailed(state, payload);
    case actionTypes.UPDATE_USER_REDUCER_DATA:
      return updateUserReducerData(state, payload);
    default:
      return state;
  }
}
