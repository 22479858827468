import { switchMap } from 'rxjs'
import { ofType } from 'redux-observable'
import { actions, actionTypes } from './actions'
import * as Api from './api'

export const userprofileRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.USERPROFILE_REQUEST),
    switchMap((action) =>
      Api.getUserprofile(action.payload)
        .then((response) => {
          return actions.userprofileRequestSuccess(response)
        })
        .catch((error) => actions.userprofileRequestFailed(error))
    )
  )

export default [userprofileRequest]
