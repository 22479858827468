import { switchMap } from "rxjs";
import { ofType } from "redux-observable";
import { actions, actionTypes } from "./actions";
import * as Api from "./api";

// console.log("🚀 ~ file: orderepics.js ~ line 6 ~ actions", actions);
export const ordersRequest = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_REQUEST),
    switchMap((action) =>
      Api.getOrders(action.payload)
        .then((response) => {
          return actions.ordersRequestSuccess(response);
        })
        .catch((error) => actions.ordersRequestFailed(error))
    )
  );
function createOrderRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.CREATE_ORDER_REQUEST),
    // eslint-disable-next-line arrow-body-style
    switchMap((action) => {
      // const FD = new FormData()
      // _forIn(action.payload, (value, key) => {
      //   FD.append(key, value)
      // })
      return Api.createOrder(action.payload)
        .then((res)=> res? actions.createOrderSuccess(res) : actions.createOrderFailed())
        .catch(actions.createOrderFailed);
    })
  );
}

function deleteOrderRequest(action$) {
  return action$.pipe(
    ofType(actionTypes.DELETE_ORDER_REQUEST),
    // eslint-disable-next-line arrow-body-style
    switchMap((action) => {
      // const FD = new FormData()
      // _forIn(action.payload, (value, key) => {
      //   FD.append(key, value)
      // })
      return Api.deleteOrder(action.payload)
        .then(actions.deleteOrderSuccess)
        .catch(actions.deleteOrderFailed);
    })
  );
}

export default [createOrderRequest, ordersRequest, deleteOrderRequest];
